import React, { createContext, useContext, useEffect, useState } from 'react'
import promoJson from '../../static/promoProps.json'
import { Promo, Promos } from 'src/interfaces/Promo'

interface PromoContextValue {
  promos: Promos | null
  isLoading: boolean
  error: Error | null
}

const PromoContext = createContext<PromoContextValue | undefined>(undefined)

interface PromoProviderProps {
  children: React.ReactNode
}

const fetchActivePromo = async (): Promise<Promo> => {
  const response = await fetch('/api/promos/active')

  if (!response.ok) {
    throw new Error('Failed to fetch active promotion')
  }

  const data = await response.json()

  return {
    ...data,
    validUntil: new Date(data.validUntil),
  }
}

export const PromoProvider: React.FC<PromoProviderProps> = ({
  children,
}: PromoProviderProps) => {
  const [promos, setPromos] = useState<Promos | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    setPromos(promoJson)
  }, [])

  const value: PromoContextValue = {
    promos,
    isLoading,
    error,
  }

  return <PromoContext.Provider value={value}>{children}</PromoContext.Provider>
}

export const usePromo = (): PromoContextValue => {
  const context = useContext(PromoContext)

  if (context === undefined) {
    throw new Error('usePromo must be used within a PromoProvider')
  }

  return context
}

export default PromoProvider
