import React, { useEffect, useState } from 'react'
import cookie from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'
import { navigate } from 'gatsby'
import { Header } from 'src/components/header'
import { Footer } from 'src/components/footer'
import { ampli, LoadOptions, Environment } from '../ampli'
import 'src/styles/main.css'
import { HeadlineLink } from 'src/interfaces/HeaderNavbar'
import { FooterProps } from 'src/interfaces/Footer'
import { GlobalBannerProps } from 'src/interfaces/GlobalBanner'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import headerNavJson from '../../static/headerNavProps.json'
import footerJson from '../../static/footerProps.json'
import globalBannerJson from '../../static/globalBannerProps.json'
import redirectsJson from '../../static/redirects.json'
import ErrorBoundary from 'src/logger/ErrorBoundary'

declare global {
  interface Window {
    podscribe: (action: string, config?: any) => void
    aap?: (config: { pixelKey: string }) => void
    acuityAdsEventQueue?: any[]
    acuityPiggybackCallback?: (e: any) => void
    acuityParseResponse?: (e: any) => void
  }
}

const Layout = ({
  children,
  location,
}: {
  children: any
  location: Location
}) => {
  const [headerHeadlineLinks, setHeaderHeadlineLinks] = useState<
    HeadlineLink[]
  >([])
  const [footerProps, setFooterProps] = useState<FooterProps>()
  const [globalBannerProps, setGlobalBannerProps] = useState<
    GlobalBannerProps
  >()
  const [stayingInMarketingSite, setStayingInMarketingSite] = useState<boolean>(
    true
  )

  useEffect(() => {
    setHeaderHeadlineLinks(headerNavJson.links)
    setFooterProps(footerJson)
    setGlobalBannerProps(globalBannerJson)
  }, [])

  useEffect(() => {
    const redirects = redirectsJson?.redirectArray
    if (redirects && redirects.length > 0) {
      const redirect = redirects.find(
        (redirect: any) => redirect.originPath === location.pathname
      )
      if (redirect) {
        if (redirect.isExternal) {
          setStayingInMarketingSite(false)
          document.location.href = redirect.redirectPath
          return
        }
        navigate(redirect.redirectPath)
      }
    }
  }, [location])

  useEffect(() => {
    if (!location.hash) {
      return
    }
    const interval = setInterval(() => {
      const element = document.querySelector(location.hash)
      if (!element) {
        return
      }
      const yOffset = -99
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
      if (element) {
        clearInterval(interval)
      }
    }, 300)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (!cookie.get('UUID')) {
      cookie.set('UUID', uuidv4(), { expires: 30 })
    }
  })

  useEffect(() => {
    if (!ampli.isLoaded) {
      const loadConfig: LoadOptions = {
        environment: process.env.GATSBY_AMPLITUDE_ENVIRONMENT as Environment,
        client: { configuration: { defaultTracking: true } },
      }
      console.log('Initializing Ampli with', { loadConfig })
      ampli.load(loadConfig)
      const sessionReplayTracking = sessionReplayPlugin()
      ampli.client.add(sessionReplayTracking)
    }
  }, [])

  // Podscribe pixel integration
  useEffect(() => {
    const script = document.createElement('script')
    script.id = 'podscribe-capture'
    script.async = true
    script.src = 'https://d34r8q7sht0t9k.cloudfront.net/tag.js'
    script.addEventListener('load', () => {
      window.podscribe('init', {
        user_id: '16b474b9-e875-4514-8381-c5c5b053cecc',
        advertiser: 'tia',
      })
      window.podscribe('view')
    })
    document.head.appendChild(script)

    return () => {
      const existingScript = document.getElementById('podscribe-capture')
      if (existingScript) {
        existingScript.remove()
      }
    }
  }, [])

  // AcuityAds pixel integration
  useEffect(() => {
    const script = document.createElement('script')
    script.id = 'acuityads-pixel'
    script.async = true
    script.src = 'https://origin.acuityplatform.com/event/v2/pixel.js'

    script.addEventListener('load', () => {
      if (window.aap) {
        window.aap({ pixelKey: '6241371298734219010' })
      }
    })

    if (!window.aap) {
      window.acuityAdsEventQueue = []
      window.aap = function (e) {
        ;(window.acuityAdsEventQueue = window.acuityAdsEventQueue || []).push(e)
      }
      window.acuityPiggybackCallback = function (e) {
        if (window.acuityParseResponse) {
          window.acuityParseResponse(e)
        }
      }
    }

    document.head.appendChild(script)

    return () => {
      const existingScript = document.getElementById('acuityads-pixel')
      if (existingScript) {
        existingScript.remove()
      }
    }
  }, [])

  return (
    <ErrorBoundary>
      {stayingInMarketingSite && (
        <React.Fragment>
          <div className="oh">
            <a
              name="maincontent"
              className="fix top left z10 skip"
              href="#maincontent"
            >
              Skip to main content
            </a>
            <Header
              location={location.pathname}
              headerNavProps={headerHeadlineLinks}
              globalBannerProps={globalBannerProps as GlobalBannerProps}
            />
            <main id="maincontent" className="site__main">
              {children}
              {footerProps && <Footer footerProps={footerProps} />}
            </main>
          </div>
        </React.Fragment>
      )}
    </ErrorBoundary>
  )
}

export default Layout
