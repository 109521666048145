const textQuery = `
  markDefs[]{
    ...,
    _type == "internalLink" => {
      ...,
      reference->
    },

    _type == "annotation" => {
      ...,
      text[] {
        ...,
        markDefs[] {
          ...,
          _type == "annotationLink" => {
            ...,
            reference->
          }
        }
      }
    },
  },
  _type == "featureCard" => {
    ...,
    image {
      asset->
    },
    text[] {
      ...,
      _type == "buttonBlock" =>  {
        ...,
        items[] {
          ...,
          reference->,
          file {
            ...,
            asset->
          }
        }
      }
    }
  },
  _type == 'editorialImageCarousel' => {
    ...,
    items[] {
      ...,
      imageCaption {
        ...,
        image {
          asset->
        }
      }
    }
  },
  _type == "featuredTopic" => {
    ...,
    topics[]-> {
      ...,
      content {
        ...,
        main {
          ...,
          image {
            asset->
          }
        }
      }
    }
  },
  _type == "buttonBlock" => {
    ...,
    items[] {
      ...,
      reference->,
      file {
        ...,
        asset->
      }
    }
  },
  _type == "editorialImageAndText" => {
    ...,
    imageCaption {
      image {
        asset->
      }
    }
  },
  _type == "imageAndText" => {
    ...,
    image {
      asset->
    },
    text[] {
      ...
    }
  },
  _type == "cta" => {
    ...,
    text[] {
      ...,
      _type == "buttonBlock" => {
        ...,
        items[] {
          ...,
          reference->,
          file {
            ...,
            asset->
          }
        }
      }
    }
  }
`

const typesQuery = `    _type == 'editorialImageAndText' => {
      ...,
      imageCaption {
        ...,
        image {
          asset->
        }
      }
    },
    _type == 'joinWidget' => {
      ...,
      shapes[] {
        ...,
        asset->
      }
    },
    _type == 'editorialText' => {
      ...,
      editorialText[] {
        ...,
        ${textQuery}
      }
    },
    _type == 'teamGrid' => {
      ...,
      people[]-> {
        ...,
        content {
          ...,
          main {
            ...,
            image {
              asset->
            }
          }
        }
      },
      featuredPeople[]-> {
        ...,
        content {
          ...,
          main {
            ...,
            image {
              asset->
            },
            featuredImage {
              asset->
            }
          }
        }
      }
    },
    _type == 'insuranceModule' => {
      ...,
      insurers[]-> {
        ...,
        image {
          asset->
        }
      }
    },
    _type == 'eventsModule' => {
      ...,
      manualEvents[]-> {
        ...,
        content {
          ...,
          main {
            ...,
            image {
              asset->
            },
            location->
          }
        }
      }
    },
    _type == 'appointmentsModule' => {
      ...,
      items[]-> {
        ...,
        content {
          ...,
          main {
            ...,
            appointmentLink->,
            image {
              asset->
            },
            tags[]-> {
              ...,
              main {
                ...,
                content {
                  ...,
                  appointmentLink->
                }
              }
            }
          }
        }
      }
    },
    _type == 'furtherReading' => {
      ...,
      articles[]->
    },
    _type == 'carousel' => {
      ...,
      items[]-> {
        ...,
        content {
          ...,
          main {
            ...,
            image {
              asset->
            }
          }
        }
      },
      text[] {
        ...,
        ${textQuery}
      }
    },
    _type == 'featuredArticles' => {
      ...,
      articles[]-> {
        ...,
        content {
          ...,
          main {
            ...,
            image {
              asset->
            },
            authors[]->
          }
        }
      }
    },
    _type == 'faqModule' => {
      ...,
      faqs[]->
    },
    _type == 'membershipCard' => {
      ...,
      text[] {
        ...,
        ${textQuery}
      }
    },
    _type == 'standardText' => {
      ...,
      text[] {
        ...,
        ${textQuery}
      }
    },
    _type == 'imageAndText' => {
      ...,

      image {
        asset->
      },
      text[] {
        ...,
        buttonBlock {
          ...,
          items[] {
            ...,
            reference->,
            file {
              ...,
              asset->
            }
          }
        },
        ${textQuery}
      }
    },
    _type == 'cta' => {
      ...,
      text[] {
        ...,
        ${textQuery}
      }
    },
    _type == 'note' => {
      ...,
      image {
        asset->
      },
      text[] {
        ...,
        ${textQuery}
      }
    },
    _type == 'testimonialCarousel' => {
      ...,
      items[] {
        ...,
        text[] {
          ...,
          ${textQuery}
        },
        image {
          asset->
        }
      }
    },
     _type == 'testimonials' => {
      ...,
      testimonials[]->
    },
    _type == 'hero' => {
      ...,
      shapes[] {
        ...,
        asset->
      },
      text[] {
        ...,
        ${textQuery}
      }
    },
    _type == 'locationsList' => {
      ...,
      locations[]-> {
        ...,
        content {
          ...,
          main {
            ...,
            image {
              asset->
            },
            logo {
              asset ->
            },
            services[]->
          }
        }
      }
    },
    _type == 'imageText' => {
      ...,
      image {
        asset-> {
          'id': _id
        }
      },
      text[] {
        ...,
        ${textQuery}
      }
    },
    _type == 'cardCarousel' => {
      ...,
      locations[]-> {
        ...,
        content {
          ...,
          main {
            ...,
            image {
              asset->
            },
            logo {
              asset ->
            },
            services[]->
          }
        }
      }
    }
  `

const experimentQuery = `
  ...,
  controlModules[] {
    ...,
    ${typesQuery}
  },
  variantModules[] {
    ...,
    ${typesQuery}
  },
`

const conditionalQuery = `
  ...,
  metModules[] {
    ...,
    ${typesQuery}
  },
  unmetModules[] {
    ...,
    ${typesQuery}
  },
`

const modulesQuery = `
  modules[] {
    ...,
    ${typesQuery},
    ${experimentQuery}
    ${conditionalQuery}
  }
`

module.exports.pageQuery = `
  ...,
  content {
    ...,
    main {
      ...,
      ${modulesQuery}
    }
  }
`

module.exports.editorialQuery = `
  ...,
  content {
    ...,
    main {
      ...,
      authors[]-> {
        ...,
        content {
          ...,
          main {
            ...,
            image {
              asset->
            }
          }
        }
      },
      image {
        asset->
      },
      editorialText[] {
        ...,
        ${textQuery}
      },
      articleType->,
      ${modulesQuery}
    }
  }
`

module.exports.appointmentQuery = `
  ...,
  content {
    ...,
    main {
      ...,
      appointmentLink->,
      image {
        asset->
      },
      ${modulesQuery}
    }
  }
`

module.exports.locationQuery = `
...,
content {
  ...,
  main {
    ...,
    testimonials {
      ...,
    },
    ${modulesQuery}
  }
}
`

module.exports.teamQuery = `
  ...,
  content {
    ...,
    main {
      ...,
      appointmentLink->,
      image {
        asset->
      },
      serviceImage {
        asset->
      },
      facts[] {
        ...,
        image {
          asset->
        },
      },
      locations[]-> {
        ...,
        content {
          ...,
          main {
            ...,
            image {
              asset->
            },
            logo {
              asset ->
            },
            services[]->
          }
        }
      },
      ${modulesQuery}
    }
  }
`

module.exports.serviceQuery = `
  ...,
  content {
    ...,
    main {
      ...,
      appointmentLink->,
      image {
        asset->
      },
      serviceImage {
        asset->
      },
      ${modulesQuery}
    }
  }
`

module.exports.headerNav = `*[_id == "headerNav"][0]  {
  ...
}`

module.exports.footer = `*[_id == "footer"][0]  {
  ...,
  icons[] {
    ...,
    footerIcon {
      asset->
    }
  }
}`

module.exports.globalBanner = `*[_id == "globalBanner"][0]  {
  ...,
  desktopBanner {
    asset->
  },
  tabletBanner {
    asset->
  },
  mobileBanner {
    asset->
  }
}`
