export enum AcuityButtonType {
  JOIN_TIA = 'JOIN_TIA',
  CHECK_NETWORK = 'CHECK_NETWORK',
  BOOK_APPOINTMENT = 'BOOK_APPOINTMENT',
}

interface AcuityPixelConfig {
  pixelKey: string
  pg: number
}

const ACUITY_BUTTON_CONFIGS: Record<AcuityButtonType, AcuityPixelConfig> = {
  [AcuityButtonType.JOIN_TIA]: {
    pixelKey: '6241371298734219010',
    pg: 39732,
  },

  [AcuityButtonType.CHECK_NETWORK]: {
    pixelKey: '6241371298734219010',
    pg: 39734,
  },

  [AcuityButtonType.BOOK_APPOINTMENT]: {
    pixelKey: '6241371298734219010',
    pg: 39736,
  },
}

export const trackButtonClick = (buttonType: AcuityButtonType): void => {
  if (typeof window === 'undefined') return

  if (window.aap) {
    const config = ACUITY_BUTTON_CONFIGS[buttonType]

    try {
      window.aap(config)
    } catch (error) {
      console.error('Failed to fire Acuity pixel:', error)
    }
  } else {
    console.warn('Acuity pixel not initialized')
  }
}
