import { LocationTemplateProps } from 'src/interfaces/Location'

export const generateLocationSchema = ({
  pageContext,
  path,
}: LocationTemplateProps) => {
  const { main, meta } = pageContext

  const schema: any = {
    '@context': 'https://schema.org',
    '@type': ['LocalBusiness', 'MedicalClinic'],
    '@id': `https://asktia.com/locations/${path}/#LocalBusiness`,
    ParentOrganization: 'Tia Women’s Health',
    name: main.title,
    url: `https://www.asktia.com/locations/${path}`,
  }

  if (main.image?.asset?.url) {
    schema.image = main.image.asset.url
  }

  if (main?.address) {
    const cleanAddress = main.address
      .replace(/\n/g, ', ')
      .replace(/\s+/g, ' ')
      .replace(/,\s*,/g, ',')
      .trim()

    const [
      streetAddress,
      addressLocality,
      addressRegion,
      postalCode,
    ] = cleanAddress.split(',')

    schema.address = {
      '@type': 'PostalAddress',
      streetAddress,
      addressLocality,
      addressRegion,
      postalCode,
      addressCountry: 'US',
    }
  }

  if (main.geo?.latitude && main.geo?.longitude) {
    schema.geo = {
      '@type': 'GeoCoordinates',
      latitude: main.geo.latitude,
      longitude: main.geo.longitude,
    }
  }

  if (main.telephone) {
    schema.telephone = main.telephone
  }

  if (main.openingHours?.length) {
    schema.openingHoursSpecification = main.openingHours.map(spec => ({
      '@type': 'OpeningHoursSpecification',
      dayOfWeek: spec.dayOfWeek,
      opens: spec.opens,
      closes: spec.closes,
    }))
  }

  if (main.priceRange) {
    schema.priceRange = main.priceRange
  }

  if (meta?.metaDescription) {
    schema.description = meta.metaDescription
  }

  schema.sameAs = [
    'https://www.instagram.com/asktia',
    'https://twitter.com/asktia',
    'https://www.facebook.com/asktia',
  ]

  return schema
}
