const handleSlug = (type, slug) => {
  switch (type) {
    case 'page':
      return `/${slug}`
    case 'location':
      return `/locations/${slug}`
    case 'appointment':
      return `/services/${slug}`
    case 'service':
      return `/services/${slug}`
    case 'editorial':
      return `/article/${slug}`
    case 'team':
      return `/team/${slug}`
    case 'join':
      return `/join/${slug}`
    default:
      return `/${slug}`
  }
}

function toPlainText(blocks = []) {
  return blocks
    .map(block => {
      if (block._type !== 'block' || !block.children) {
        return ''
      }
      return block.children.map(child => child.text).join('')
    })
    .join('\n\n')
}

module.exports = {
  handleSlug,
  toPlainText,
}
