import React, { useLayoutEffect, useEffect, useState } from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'
import Modal from 'react-modal'
import jump from 'jump.js'
import { saveLead } from 'src/api/saveLead'

import { handleSlug } from 'src/utils/utils'

import { ampli } from 'src/ampli'
import { getWidgetUrl } from '../global/joinWidget'
import cookie from 'js-cookie'
import { AcuityButtonType, trackButtonClick } from 'src/utils/accuityEvents'

Modal.setAppElement(`#___gatsby`)

export interface ButtonBlockProps {
  data: {
    onlyStackButtons?: boolean
    items: Array<{
      url?: string
      _key: string
      linkType: string
      backgroundColor: string
      hoverBackgroundColor: string
    }>
  }
  style?: React.CSSProperties
}

const setUpLeadFormToSubmit = ({
  saveLead,
  campaignName,
}: {
  saveLead: any
  campaignName: string
}) => {
  // TODO: need to grab this in a more specific way than an iframe
  //@ts-ignore
  const form = document.querySelector('.ab-in-app-message')?.contentWindow
    .document.body
  //@ts-ignore
  const submitButton = form.querySelector('#submit')

  submitButton?.addEventListener('click', async () => {
    // TODO: need to grab other event properties
    // guaranteed by lead form contract
    //@ts-ignore
    if (form) {
      const email = form
        .querySelector('#email')
        //@ts-ignore
        .value.replace(/^\s+|\s+$/g, '')
        .toLowerCase()

      //@ts-ignore
      const otherInputs = form.querySelectorAll('input')
      const eventProperties = {}
      otherInputs.forEach(input => {
        if (input.id && input.value && input.id !== 'email') {
          //@ts-ignore
          eventProperties[input.id] = input.value
        }
      })
      try {
        await saveLead({ email: email, campaignName, eventProperties })
      } catch (err) {
        console.log('something went wrong', err)
      }
    }
  })
}

export const ButtonBlock = ({ data, style = {} }: ButtonBlockProps) => {
  const { items, onlyStackButtons = false } = data
  const [modalIsOpen, setIsOpen] = React.useState(false)

  // Hardcoding this experiment since we are facing issues with amplitude experiment
  // We should only show to devices with width <= 600 and on the join page
  const [
    isStandAloneSignUpOnTreatment,
    setIsStandAloneSignUpOnTreatment,
  ] = useState(false)

  const [leadButtonVisible, setLeadButtonVisible] = useState(false)

  // This is needed because react/gatsby gets in a weird state for the first render if this effect is not present
  useLayoutEffect(() => {
    const isTreatment = typeof window !== 'undefined'

    if (isTreatment !== isStandAloneSignUpOnTreatment) {
      setIsStandAloneSignUpOnTreatment(isTreatment)
    }
  }, [])

  const [contact, setContact] = useState({
    subject: 'Asktia.com - Get in touch Form',
    name: '',
    lastName: '',
    email: '',
    phone: '',
    organization: '',
    message: '',
  })
  const [response, setResponse] = useState({
    type: '',
    message: '',
  })

  function openModal() {
    setIsOpen(true)
  }
  function closeModal() {
    setIsOpen(false)
    setResponse({ type: '', message: '' })
  }
  const renderButton = (item: any, i: number, length: number) => {
    useEffect(() => {
      // these functions are defined in script tags at the beggining of the <head>
      // @ts-ignore
      if (
        typeof window !== 'undefined' &&
        window?.reloadOTBanner &&
        item.linkType === 'one-trust-consent-modal'
      ) {
        // @ts-ignore
        window.reloadOTBanner()
      }
    }, [])

    useEffect(() => {
      if (typeof window !== 'undefined' && item.linkType === 'leadForm') {
        import('@braze/web-sdk').then(
          ({
            initialize,
            openSession,
            subscribeToInAppMessage,
            showInAppMessage,
            logCustomEvent,
          }) => {
            initialize(process.env.GATSBY_BRAZE_WEB_API_KEY || '', {
              baseUrl: 'sdk.iad-02.braze.com',
              allowUserSuppliedJavascript: true,
              enableLogging: true,
              minimumIntervalBetweenTriggerActionsInSeconds: 0,
            })

            const hasSeenCampaign = () => {
              return !cookie.get(`open-${item?.campaignName}`)
            }

            const openCampaignModal = (
              message: any,
              container: any | undefined = undefined
            ) => {
              cookie.set(
                `open-${item?.campaignName}`,
                `open-${item?.campaignName}`,
                { expires: 1 }
              )
              if (container) {
                showInAppMessage(message, container)
              } else {
                showInAppMessage(message, null, () => {
                  setUpLeadFormToSubmit({
                    saveLead,
                    campaignName: item?.campaignName,
                  })
                })
              }
            }

            // later on need to filter for correct campaign using message.extras.title
            subscribeToInAppMessage((message: any) => {
              if (message.extras.title === item?.campaignName) {
                setLeadButtonVisible(true)

                if (hasSeenCampaign()) {
                  setTimeout(() => {
                    // it is possible someone has clicked on button since timeout started
                    if (hasSeenCampaign()) {
                      openCampaignModal(message)
                    }
                  }, Number(item?.delayTrigger) * 1000)
                }
                const container = document.getElementById('braze-feed')
                const openModalButton = document.querySelector(
                  '#openInAppMessageModal'
                )
                // @ts-ignore
                openModalButton.onclick = () =>
                  openCampaignModal(message, container)
                // @ts-ignore
                openModalButton.onclick = () => openCampaignModal(message)
              }
            })

            openSession()
            logCustomEvent(`leadform-${item?.campaignName}-triggered`)
          }
        )
      }
    }, [])

    if (item.linkType === 'leadForm' && leadButtonVisible) {
      return (
        <button
          id="openInAppMessageModal"
          className={cx(
            'inline-block button--join jcc aic cb f rel',
            item.backgroundColor,
            {
              bcorange: item.backgroundColor === undefined,
              cw:
                item.backgroundColor === 'bcraspberry' ||
                item.backgroundColor === 'bcp' ||
                item.backgroundColor === 'bcb',
            }
          )}
          style={{ cursor: 'pointer' }}
        >
          <span className="z1 rel">{item?.linkCta || 'Learn More'}</span>
        </button>
      )
    }

    if (item.linkType === 'modal-partnering') {
      return (
        <a
          href="#modal"
          onClick={openModal}
          className={cx('button no-underline f', item.backgroundColor, {
            'button--bump': length > 1,
            bctran: item.backgroundColor === undefined,
            'button--border':
              !item.backgroundColor || item.backgroundColor === 'bctran',
            cw:
              item.backgroundColor === 'bcraspberry' ||
              item.backgroundColor === 'bcp' ||
              item.backgroundColor === 'bcb',
          })}
        >
          {item.linkCta}
        </a>
      )
    }

    if (item.linkType === 'one-trust-consent-modal') {
      return (
        <a
          id="ot-sdk-btn"
          className={cx('ot-sdk-show-settings', item.backgroundColor, {
            bctran: item.backgroundColor === undefined,
            cw:
              item.backgroundColor === 'bcraspberry' ||
              item.backgroundColor === 'bcp' ||
              item.backgroundColor === 'bcb',
          })}
          style={{
            color: '#831a4a',
            padding: 0,
            border: 'none',
            background: 'inherit',
            fontSize: '18px',
            fontFamily: 'basis-grotesque-regular,serif',
            cursor: 'pointer',
          }}
        >
          {item.linkCta}
        </a>
      )
    }

    // "Widget" button type
    if (item.linkType === 'join-scroll-shape' || item.linkType === undefined) {
      return (
        <a
          style={{ ...style, backgroundColor: item.backgroundColor }}
          href={
            item.url
              ? item.url
              : isStandAloneSignUpOnTreatment
              ? getWidgetUrl()
              : '#jump'
          }
          onClick={async e => {
            e.preventDefault()

            const url = item.url
              ? item.url
              : isStandAloneSignUpOnTreatment
              ? getWidgetUrl()
              : '#jump'

            const trackingFunctions: Array<() => void> = [
              () => ampli.joinNowHeroButtonClicked(),
            ]

            const linkCta = item?.linkCta?.toLowerCase()

            if (linkCta?.includes('join')) {
              trackingFunctions.push(() =>
                trackButtonClick(AcuityButtonType.JOIN_TIA)
              )
            } else if (linkCta?.includes('network')) {
              trackingFunctions.push(() =>
                trackButtonClick(AcuityButtonType.CHECK_NETWORK)
              )
            } else if (linkCta?.includes('appointment')) {
              trackingFunctions.push(() =>
                trackButtonClick(AcuityButtonType.BOOK_APPOINTMENT)
              )
            }

            trackingFunctions.forEach(fn => fn())

            if (isStandAloneSignUpOnTreatment) {
              return
            }

            // wait for some time so the evetns have time to fire
            await new Promise(resolve => setTimeout(resolve, 300))

            if (url === '#jump') {
              jump(`.joinWidget`, {
                offset: -200,
              })
            } else {
              window.location.href = url
            }
          }}
          className={cx(
            'button no-underline button--join jcc aic cb f rel f',
            item.backgroundColor,
            {
              'button--bump': length > 1,
              bcorange: item.backgroundColor === undefined,
              cw:
                item.backgroundColor === 'bcraspberry' ||
                item.backgroundColor === 'bcp' ||
                item.backgroundColor === 'bcb',
            }
          )}
        >
          <span className="z1 rel">{item.linkCta}</span>
        </a>
      )
    }
    if (item.url) {
      return (
        <a
          target="_blank"
          href={
            item.linkType === 'join' && isStandAloneSignUpOnTreatment
              ? getWidgetUrl()
              : item.url
          }
          onClick={async e => {
            e.preventDefault()

            const url =
              item.linkType === 'join' && isStandAloneSignUpOnTreatment
                ? getWidgetUrl()
                : item.url

            const trackingFunctions: Array<() => void> = []
            const linkCta = item?.linkCta?.toLowerCase() || ''

            if (linkCta?.includes('rsvp')) {
              trackingFunctions.push(() =>
                ampli.eventRsvp({ eventUrl: item.url })
              )
            }

            if (linkCta?.includes('join')) {
              trackingFunctions.push(() =>
                trackButtonClick(AcuityButtonType.JOIN_TIA)
              )
            } else if (linkCta?.includes('network')) {
              trackingFunctions.push(() =>
                trackButtonClick(AcuityButtonType.CHECK_NETWORK)
              )
            } else if (linkCta?.includes('appointment')) {
              trackingFunctions.push(() =>
                trackButtonClick(AcuityButtonType.BOOK_APPOINTMENT)
              )
            }

            trackingFunctions.forEach(fn => fn())

            await new Promise(resolve => setTimeout(resolve, 300))

            if (url === '#jump') {
              jump(`.joinWidget`, {
                offset: -200,
              })
            } else {
              window.location.href = url
            }
          }}
          className={cx('inline-block', item.backgroundColor, {
            'button no-underline f': item.linkType === 'button',
            'button--bump': length > 1,
            bctran:
              item.backgroundColor === undefined && item.linkType === 'button',
            'button--border':
              item.linkType === 'button' &&
              (!item.backgroundColor || item.backgroundColor === 'bctran'),
            cw:
              item.backgroundColor === 'bcraspberry' ||
              item.backgroundColor === 'bcp' ||
              item.backgroundColor === 'bcb',
          })}
          style={style}
        >
          <span className="z1 rel">{item.linkCta}</span>
        </a>
      )
    } else if (item.reference) {
      return (
        <Link
          to={handleSlug(
            item.reference._type,
            item.reference.content.main.slug.current
          )}
          className={cx('inline-block rel', item.backgroundColor, {
            'button no-underline f': item.linkType === 'button',
            'button--bump': length > 1,
            bctran:
              item.backgroundColor === undefined && item.linkType === 'button',
            'button--border':
              item.linkType === 'button' &&
              (!item.backgroundColor || item.backgroundColor === 'bctran'),
            cw:
              item.backgroundColor === 'bcraspberry' ||
              item.backgroundColor === 'bcp' ||
              item.backgroundColor === 'bcb',
          })}
        >
          <span className="z1 rel">{item.linkCta}</span>
        </Link>
      )
    } else if (item.file) {
      return (
        <a
          target="_blank"
          href={
            item.linkType === 'join' && isStandAloneSignUpOnTreatment
              ? getWidgetUrl()
              : item.file.asset.url
          }
          onClick={() => {
            if (item.linkCta.toLowerCase() === 'rsvp') {
              ampli.eventRsvp({ eventUrl: item.file.asset.url })
            }
          }}
          className={cx('inline-block', item.backgroundColor, {
            'button no-underline f': item.linkType === 'button',
            'button--bump': length > 1,
            bctran:
              item.backgroundColor === undefined && item.linkType === 'button',
            'button--border':
              item.linkType === 'button' &&
              (!item.backgroundColor || item.backgroundColor === 'bctran'),
            cw:
              item.backgroundColor === 'bcraspberry' ||
              item.backgroundColor === 'bcp' ||
              item.backgroundColor === 'bcb',
          })}
        >
          <span className="z1 rel">{item.linkCta}</span>
        </a>
      )
    }
  }
  const handleSubmit = (e: React.FormEventHandler) => {
    e.preventDefault()

    fetch('https://formsubmit.co/ajax/health-systems@asktia.com', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(contact),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success === 'true') {
          setResponse({
            type: 'success',
            message: 'Mail sent successfully',
          })
        } else {
          setResponse({
            type: 'fail',
            message: 'Failed to send email',
          })
        }
      })
      .catch(error => {
        setResponse({
          type: 'fail',
          message: 'Failed to send email',
        })
      })
  }

  const handleChange = e => {
    setContact({ ...contact, [e.target.name]: e.target.value })
  }

  return (
    <div
      className="f fw button--block mb1 pt10 mb20 jcs aic"
      style={
        onlyStackButtons
          ? { flexDirection: 'column', alignContent: 'flex-start' }
          : {}
      }
    >
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="bcblack cw"
        contentLabel="Inquire Modal Form"
      >
        <button
          onClick={closeModal}
          className="no-style z2 partnering__close abs cw right top"
        >
          <svg width="52" height="50" viewBox="0 0 52 50" fill="none">
            <line
              x1="2.53033"
              y1="1.46967"
              x2="50.5303"
              y2="49.4697"
              stroke="white"
              stroke-width="1.5"
            />
            <line
              x1="1.46967"
              y1="49.4697"
              x2="49.4697"
              y2="1.46967"
              stroke="white"
              stroke-width="1.5"
            />
          </svg>
        </button>
        <div className="container--1000 mxa outer pys pym">
          <div
            className={cx('rel partnering__success p1 mxa', {
              hide: response.type !== 'success',
            })}
          >
            <img src="/partner-form.png" alt="" />
            <h2>
              Thanks for your interest! The Tia Partnerships team will be in
              touch soon.
            </h2>
          </div>
          <div
            className={cx('rel partnering__success p1 mxa', {
              hide: response.type !== 'fail',
            })}
          >
            <img src="/partner-form.png" alt="" />
            <h2>Sorry e-mail has not been sent, please try again later!</h2>
          </div>
          <div
            className={cx('rel partnering__success p1 mxa', {
              hide: response.type !== 'fail',
            })}
          >
            <img src="/partner-form.png" alt="" />
            <h2>Sorry e-mail has not been sent, please try again later!</h2>
          </div>
          <div
            className={cx('rel p1 mxa', {
              hide: response.type === 'success' || response.type === 'fail',
            })}
          >
            <div>
              <h3 className="h1">Interested in partnering?</h3>
            </div>
            <form
              onSubmit={e => handleSubmit(e)}
              method="post"
              className="block partnering__form"
            >
              <input
                onChange={handleChange}
                className="x ss18 block karla cw p05"
                name="name"
                placeholder="First name*"
                required
              />
              <input
                onChange={handleChange}
                className="x ss18 block karla cw p05"
                name="lastName"
                placeholder="Last name*"
                required
              />
              <input
                onChange={handleChange}
                className="x ss18 block karla cw p05"
                name="email"
                type="email"
                placeholder="Email*"
                required
              />
              <input
                onChange={handleChange}
                className="x ss18 block karla cw p05"
                name="phone"
                placeholder="Phone*"
                required
              />
              <input
                onChange={handleChange}
                className="x ss18 block karla cw p05"
                name="organization"
                placeholder="Organization*"
                required
              />

              <input
                onChange={handleChange}
                type="text"
                className="hide"
                name="honeypot"
              />

              <input
                onChange={handleChange}
                type="hidden"
                name="subject"
                value="Partnering Inquiry - Tia"
              />
              <textarea
                onChange={handleChange}
                className="x ss18 block karla f cw mb1 p05"
                name="message"
                required
                placeholder="Message*"
              ></textarea>
              <button className="button cw no-underline mt1 bctran f">
                <span className="z1 ss16 rel">Submit</span>
              </button>
            </form>
          </div>
        </div>
      </Modal>
      {items?.length > 0 &&
        items.map((item, i) => (
          <React.Fragment key={item._key}>
            {renderButton(item, i, items.length)}
          </React.Fragment>
        ))}
    </div>
  )
}
