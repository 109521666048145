// If you don't want to use TypeScript you can delete this file!
import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'
import { useIntersection } from 'react-use'

import BlockContent from '@sanity/block-content-to-react'

import { SmallArrow } from 'src/components/svgs'
import { SEO } from 'src/components/seo'
import { generateSchema, SchemaProps } from 'src/components/schema'
import {
  EditorialSerializer,
  EditorialFootnoteSerializer,
} from 'src/utils/editorialSerializer'
import { EditorialHeader } from 'src/components/editorial/editorialHeader'
import { GuideHeader } from 'src/components/editorial/guideHeader'
import Content from 'src/components/content'

export interface EditorialProps {
  pageContext: {
    main: {
      modules: []
      articleType?: {
        title: string
      }
      editorialTexT: any[]
      articleColor: string
      title: string
      image: {
        asset: {
          _id: string
        }
      }
    }
    meta: {}
    env: 'production' | 'development' | 'local' | 'staging'
  }
  path: string
}

const Editorial = ({ path, pageContext }: EditorialProps) => {
  const {
    main: { modules, editorialText, backgroundColor, articleType },
    metaInformation,
    env,
  } = pageContext
  const { main } = pageContext

  const ref = useRef(null)
  const [fixed, setFixed] = useState(false)

  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  })

  useEffect(() => {
    setTimeout(() => {
      if (window.pageXOffset < 200) {
        setFixed(false)
      }
    }, 300)
  }, [0])

  const handleFixed = value => {
    if (window.pageXOffset < 200) {
      setFixed(value)
    } else {
      setFixed(false)
    }
  }

  useEffect(() => {
    intersection &&
    intersection.intersectionRatio < 1 &&
    intersection.boundingClientRect.y > 0
      ? handleFixed(false)
      : handleFixed(true)
  }, [intersection])

  useEffect(() => {
    const footNotes = document.querySelectorAll('.js-footnote')
    const footNotesBottom = document.querySelectorAll('.js-footnote-bottom')
    const footNoteReference = document.querySelectorAll(
      '.js-footnote-reference'
    )
    if (footNotes.length > 0) {
      footNotes.forEach((note, i) => {
        footNoteReference[0].classList.remove('hide')
        footNotes[i].innerHTML = i + 1
        if (footNotesBottom[i]) {
          footNotesBottom[i].innerHTML = `${i + 1}.`
        }
      })
    }
  }, [0])

  const schema = generateSchema(({
    ...main,
    pagePath: path,
  } as unknown) as SchemaProps)

  return (
    <div
      className={cx('editorial', {
        'cb bcw': articleType?.slug.current !== 'guide',
        'cw bcpurple': articleType?.slug.current === 'guide',
        sidebar: fixed,
      })}
    >
      <SEO
        metaInfo={metaInformation}
        pagePath={path}
        env={env}
        schema={schema}
      />
      <div className="bcb">
        <Link
          to="/tiatake"
          className="container--l f jcs aic cw mxa outer p1 bold"
        >
          <SmallArrow className="block cw rotate" /> The Tia Take
        </Link>
      </div>
      {articleType?.slug.current === 'guide' ? (
        <GuideHeader {...main} />
      ) : (
        <EditorialHeader {...main} />
      )}
      <div className="editorial__core f js-editorial jcb rel">
        <div className="editorial__sizer" ref={ref} />
        <div className="x">
          <BlockContent
            blocks={editorialText}
            serializers={EditorialSerializer}
          />
        </div>
      </div>
      <div className="editorial__references py1">
        <div className="container--l outer px1 mxa  rel f jce">
          <div className="container--e x">
            <BlockContent
              blocks={editorialText}
              serializers={EditorialFootnoteSerializer}
            />
          </div>
        </div>
      </div>
      <div className="x z2 rel">
        <Content modules={modules}></Content>
      </div>
    </div>
  )
}

export default Editorial
