// If you don't want to use TypeScript you can delete this file!
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import {
  AmplitudeExperimentProvider,
  AmplitudeExperimentContext,
} from 'src/ampli/AmplitudeExperimentProvider'
import PromoProvider from 'src/context/PromoContext'
import { RenderModules } from 'src/utils/renderModules'

const queryClient = new QueryClient()

export const Content = ({ modules }: { modules: [] }) => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AmplitudeExperimentProvider>
          <PromoProvider>{RenderModules(modules)}</PromoProvider>
        </AmplitudeExperimentProvider>
      </QueryClientProvider>
    </>
  )
}

export default Content
