import { Link } from 'gatsby'
import cx from 'classnames'
import React, { useState, useEffect } from 'react'
import { ampli } from '../ampli'

import { Logo, Caret, Menu, NewLogo } from 'src/components/svgs'
import { HeadlineLink } from '../interfaces/HeaderNavbar'
import { GlobalBannerProps } from 'src/interfaces/GlobalBanner'
import classes from './header.module.css'
import { ButtonBlock } from './inline/buttonBlock'
import { AcuityButtonType, trackButtonClick } from 'src/utils/accuityEvents'

const handleTrackingAndNavigation = async (
  trackingFunction: () => void,
  url: string,
  e: React.MouseEvent
) => {
  e.preventDefault()

  trackingFunction()

  await new Promise(resolve => setTimeout(resolve, 300))
  window.location.href = url
}

export const Header = (props: {
  headerNavProps: HeadlineLink[]
  globalBannerProps: GlobalBannerProps
  location: string
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [headlineLinks, setHeadlineLinks] = useState<HeadlineLink[]>([])
  const [globalBannerProps, setGlobalBannerProps] = useState<
    GlobalBannerProps
  >()
  const [openSubmenus, setOpenSubmenus] = useState<string[]>([])

  useEffect(() => {
    setHeadlineLinks(props.headerNavProps)
  }, [props.headerNavProps])

  useEffect(() => {
    setGlobalBannerProps(props.globalBannerProps)
  }, [props.globalBannerProps])

  const closeAll = () => {
    setIsOpen(false)
  }

  const toggleSubmenu = (key: string) => {
    const submenus = [...openSubmenus]
    const indexOfKey = submenus.findIndex(item => item === key)

    if (indexOfKey === -1) {
      submenus.push(key)
      setOpenSubmenus(submenus)
    } else {
      submenus.splice(indexOfKey, 1)
      setOpenSubmenus(submenus)
    }
  }

  const handleJoinTiaClick = (e: React.MouseEvent) => {
    handleTrackingAndNavigation(
      () => {
        ampli.joinNowButtonClicked()
        trackButtonClick(AcuityButtonType.JOIN_TIA)
      },
      process.env.GATSBY_WIDGET_URL || '/',
      e
    )
  }

  return (
    <React.Fragment>
      <div className={classes.headerContainer}>
        {props.location?.includes('summer-of-self') &&
          globalBannerProps &&
          Object.keys(globalBannerProps).length === 3 && (
            <picture className={classes.banner}>
              <source
                media="(max-width:528px)"
                srcSet={globalBannerProps.mobileBanner.asset.url}
              />
              <source
                media="(max-width:834px)"
                srcSet={globalBannerProps.tabletBanner.asset.url}
              />
              <img
                src={globalBannerProps.desktopBanner.asset.url}
                alt="Banner"
                className={classes.banner}
              />
            </picture>
          )}
        <header className="header x bcb f aic top z8 left">
          <div className="container--l px1 py05 outer header__inner mxa x">
            <div className="f jcb aic x">
              <div className="f jcs aic rel">
                <Link
                  aria-label="navigate to homepage"
                  onClick={() => closeAll()}
                  className="block header__logo-wrap"
                  to="/"
                >
                  <NewLogo className="header__logo block" />
                </Link>
                <nav
                  className={cx(classes.headline_links, 'nav_show__desktop')}
                >
                  <ul>
                    {headlineLinks.map(headlineLink => {
                      if (!headlineLink.hasSublinks) {
                        return (
                          <li className="mono" key={headlineLink._key}>
                            <Link to={headlineLink.link || '#'}>
                              {headlineLink.title}
                            </Link>
                          </li>
                        )
                      }

                      return (
                        <li className="mono" key={headlineLink._key}>
                          <span>{headlineLink.title}</span>
                          <Caret />
                          <ul className={cx('bcb', classes.sublinks_menu)}>
                            {headlineLink.sublinks?.map(sublink => (
                              <li key={sublink._key}>
                                <Link to={sublink.link}>{sublink.title}</Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      )
                    })}
                  </ul>
                </nav>
              </div>
              <div className="header__desktop-links f jcb aic">
                <Link to="/tiatake" className="caps mono cw">
                  The Tia Take
                </Link>
                <a
                  onClick={handleJoinTiaClick}
                  href={process.env.GATSBY_WIDGET_URL}
                  className="button--join bcorange jcc aic f rel"
                >
                  <span className="z1 rel">Join Tia</span>
                </a>
                <a
                  href={`${process.env.GATSBY_WIDGET_URL?.split('/widget')[0]}`}
                  className="cw caps mono"
                >
                  Log in
                </a>
              </div>
              <div className="header__mobile-toggle">
                <div className="jcs f aic headerJoinWrapper">
                  <a
                    onClick={handleJoinTiaClick}
                    href={process.env.GATSBY_WIDGET_URL}
                    className="headerJoin"
                  >
                    <span>Join Tia</span>
                  </a>
                </div>
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="caps mono h5 w100 block no-style cw bcb"
                >
                  <Menu />
                </button>
              </div>
            </div>
          </div>
        </header>
      </div>
      <div
        className={cx(
          'header__mobile fix bcb z7 cw x y top',
          isOpen && 'is-open'
        )}
      >
        <div className="header__mobile-inner">
          <ul>
            <li>
              <a
                href={`${process.env.GATSBY_WIDGET_URL?.split('/widget')[0]}`}
                className="caps mono"
              >
                Log in
              </a>
            </li>
            {headlineLinks.map(headlineLink => {
              if (!headlineLink.hasSublinks) {
                return (
                  <li className="mono caps" key={headlineLink._key}>
                    <Link
                      onClick={() => setIsOpen(false)}
                      to={headlineLink.link || '#'}
                    >
                      {headlineLink.title}
                    </Link>
                  </li>
                )
              }

              return (
                <li
                  className={cx('mono caps', {
                    visible: openSubmenus.includes(headlineLink._key),
                  })}
                  onClick={() => toggleSubmenu(headlineLink._key)}
                  key={headlineLink._key}
                >
                  <Caret />
                  <span>{headlineLink.title}</span>
                  <ul className={cx('bcb', classes.sublinks_menu)}>
                    {headlineLink.sublinks?.map(sublink => (
                      <li key={sublink._key}>
                        <Link
                          onClick={() => setIsOpen(false)}
                          to={sublink.link}
                        >
                          {sublink.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              )
            })}
            <li>
              <Link
                to="/tiatake"
                onClick={() => setIsOpen(false)}
                className="caps mono"
              >
                The Tia Take
              </Link>
            </li>
          </ul>
          <ButtonBlock
            style={{
              marginLeft: 24,
              width: 'auto',
            }}
            data={{
              onlyStackButtons: true,
              items: [
                {
                  _key: '9b40d2c2ea69',
                  linkType: 'join-scroll-shape',
                  linkCta: "See if you're in network",
                },
                {
                  _key: '499e3316b0ae',
                  linkType: 'button',
                  url: '/membership',
                  linkCta: 'Learn about membership',
                },
              ],
            }}
          />
        </div>
      </div>
    </React.Fragment>
  )
}
